import Features from "../../components/Home/Features";
import Footer from "../../components/Footer";
import Downbar from "../../components/down_footer_pulizie";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Card from "../../components/Card";
import { Helmet } from "react-helmet"
import { Link } from "gatsby";
import * as React from "react";
import "../../styles/main.css";
import { pageStyles,cityDiv,mapDiv,mapSize } from "../../styles/style";
import FloatButton from "../../components/FloatButton";
import { StaticImage } from "gatsby-plugin-image";
import city from "../../images/copertura/comune_legnano.jpeg";

// manutenpul styles
// data
// cards services

const services = [
  {
    'image': <StaticImage src="../../images/services/pulizie_uffici.webp" alt="SERVIZI DI PULIZIA" />,
    'title':'SERVIZI DI PULIZIA',
    'text':'Effettuiamo Pulizie Professionali- Pulizie Uffici, Negozi, Palestre, Studi.- Pulizie Condominiali.- Pulizie in Quota fino a 20 mt.- Sanificazione Bagni, Cucine, Mense. - Pulizie Post-Ristrutturazione.- Pulizie Strutture Sanitarie.',
  },{
    'image':<StaticImage src="../../images/services/sanificazione.webp" alt="SANIFICAZIONE" />,
    'title':'SANIFICAZIONE',
    'text':'Eseguiamo servizi di sanificazione per qualsiasi ambiente civile ed industriale, a Milano e provincia (anche contro Coronvirus-COVID 19). Non è sufficiente igienizzare, ma occorre sanificare e, quindi, abbattere le cariche microbiche per ridurre il rischio di insorgenza e diffusione di patologie ed infezioni.',
  },{
    'image':<StaticImage src="../../images/services/disinfestazioni.webp" alt="DISINFESTAZIONI" />,
    'title':'DISINFESTAZIONI',
    'text':'L’esperienza maturata ci permette oggi, di poter risolvere ogni genere di minaccia accompagnandovi nelle varie fasi di intervento fino alla completa estinzione dell’infestazione. Rivolgiamo i nostri servizi di disinfestazione sia ad utenti privati che a strutture pubbliche e tutti gli interventi sono svolti in rispetto del protocollo HACCP e tutti i prodotti sono presidi medico chirurgici e dotati di scheda tecnica e sicurezza.',
  },{
    'image':<StaticImage src="../../images/services/giardinaggio.webp" alt="GIARDINAGGIO" />,
    'title':'GIARDINAGGIO',
    'text':'Un team di giardinieri si occupa di proteggere e preservare le aree verdi di immobili privati e pubblici.',
  },{
    'image':<StaticImage src="../../images/services/furgon-1.webp" alt="SGOMBERI TRASLOCHI" />,
    'title':'SGOMBERI TRASLOCHI',
    'text':'Si eseguono con puntualità e professionalità sgomberi per privati: cantine, solai, depositi, appartamenti e anche per uffici o attività commerciali.',
  },{
    'image':<StaticImage src="../../images/services/servizi-edili.webp" alt="SERVIZI EDILI" />,
    'title':'SERVIZI EDILI',
    'text':"Si effettuano ristrutturazioni edilizie complete per abitazioni residenziali, appartamenti e locali commerciali. Il team dell'impresa si occupa di ogni fase della ristrutturazione.",
  }
];

// markup
const LegnanoPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
          <meta charSet="utf-8" />
          <title>Impresa di Pulizie e Servizi LEGNANO | MANUTENPUL</title>
          <meta name="description" content="Impresa di pulizie LEGNANO: MANUTENPUL dal 1990. Affidabilità e tempestività contraddistinguno i nostri interventi di pulizia e sanificazione. Preventivo GRATUITO! Impresa di Pulizie LEGNANO - Servizi di Pulizie LEGNANO - Pulizie Professionali LEGNANO"></meta>
          <meta name="keywords" content="impresa di pulizie legnano, impresa di sanificazione legnano, pulizia condominio legnano, giardinaggio legnano"></meta>
          <meta property="og:title" content="Impresa di Pulizie a LEGNANO | MANUTENPUL"></meta>
          <meta property="og:description" content="L'area di LEGNANO è coperta al 100% dai servizi di pulizia di MANUTENPUL. Pulizia di uffici e imprese, aree produttive, cliniche e case di cura, appartamenti. 40 anni di esperienza nel settore del cleaning: scopri tutti i servizi disponibili e chiedi un preventivo gratuito, senza impegno."></meta>
      </Helmet>
      <Navbar></Navbar>
      <Downbar></Downbar>
      <Banner pageName="Impresa di Pulizie a LEGNANO" prepageName="Copertura" postpageName="Impresa di Pulizie a Legnano"/>
      
      <section className="feature-section pt-60">
        <div className="container">
          <div className="row">

              <div className="col-12 col-sm-6 mb-30 " >
                  <p>L'AREA di <strong>LEGNANO</strong> è coperta al 100% dai servizi di pulizia di MANUTENPUL.</p>
                  <p>MANUTENPUL è in grado di offriti servizi di pulizia professionale nell'area di Legnano, Piu di 30 anni di esperienza ed un team di Professionisti 24/24, 7/7 al tuo servizio per qualsiasi esegenza.</p>
                  
                  <div className="section-title text-center mt-25 mb-25">
                  <p>Chiama ora e prenota il servizio adatto a te!&nbsp; <strong><a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
                  <br/>
                    <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
                  </div>
                  <p>Duranti tutti questi anni di attività abbiamo cercato costantemente di migliorarci attraverso corsi di aggiornamento per garantire a chi cerca un’ impresa di pulizie a Legnano, la miglior <strong>QUALITA’</strong> al miglior costo.</p>
              </div>
            
              <div className="col-12 col-sm-6 mb-25" style={cityDiv}>
              <br/> 
                <img src={city} width="100%" alt="city" />
              </div>
          </div>
              
          <div className="row">
              <div className="col-12 order-2 col-sm-6  order-sm-1" style={mapDiv}>
                <iframe title="MiniMaps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11197.843497012447!2d9.08375679552683!3d45.44036816560943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c273d90f9ebd%3A0x1e5601a974756eed!2s20090%20Cesano%20Boscone%2C%20Mil%C3%A1n%2C%20Italia!5e0!3m2!1ses-419!2smx!4v1637305982832!5m2!1ses-419!2smx" width="100%" height="450" style={mapSize} loading="lazy"></iframe>
              </div>
              
              <div className="col-12 order-1  col-sm-6  mb-25 order-sm-2" >
                <br />
                <p> La nostra Ditta di pulizie professionali è specializzata nei seguenti settori:</p>
                <br/>
                <ul className="dt-sc-fancy-list green serviceMain arrow"> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Servizi di pulizie civili&nbsp;e pulizie industriali</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Sanificazione reparti alimentari e studi medici</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Derattizzazione e disinfestazione</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Fornitura materiali igienico/sanitario</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Trattamenti speciali per pavimentazioni</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Servizio lavanderia</strong></Link></li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Facchinaggio e sgombero</strong></Link>&nbsp;</li> 
                  <li><i className="material-icons">done_outline</i>&nbsp;<Link to="#"><strong>Giardinaggio / Trattamento del verde</strong></Link>&nbsp;</li> 
                </ul>

                <div className="section-title text-center mt-25 mb-25">
                  <p>Chiama ora e prenota il servizio adatto a te!&nbsp;<strong><a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
                  <br/>
                    <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
                  </div>
              </div>

           </div>
        </div>
      </section>
      <Features
      cityName={"LEGNANO"}
      text={"Abbiamo copertura a LEGNANO.  "}
      />
      <div className="container">
        <div className="row">
        <div className="col-12 order-2 col-sm-6  order-sm-1" >

                <p>  Tra i servizi più richiesti effettuati nel comune di LEGNANO abbiamo:</p>
                <br/>
                <ul className="dt-sc-fancy-list green serviceMain arrow"> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia degli Uffici e Aziende</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Scale Condominiali</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Studi medici e dentistici</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia di cucine e mense</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia Appartamenti e Privati</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia di negozi e show room</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia supermercati e centri commerciali</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizio di sanificazione reparti alimentari</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizio di facchinaggio e sgomberi</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Pulizia case di cura e/o di riposo</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Servizi di pulizia di alberghi</strong></Link></li> 
                  <li><i className="material-icons">double_arrow</i>&nbsp;<Link to="#"><strong>Fornitura di Prodotti igienici sanitari</strong></Link></li> 
                </ul>
                
        </div>
              
        <div className="col-12 order-1  col-sm-6  mb-25 order-sm-2" >
        <h4> Prova la qualità che fa la differenza! </h4>
         <p> <strong>Vuoi essere certo della nostra qualità?</strong> La qualità dei nostri servizi è testimoniata dalle nostre ottime referenze che potrai trovare nel “ Portfolio clienti”.
          <br/>
          I nostri clienti e la soddisfazione di questi è un punto fondamentale per la nostra ditta di pulizie professionali: attraverso i nostri clienti, noi riusiamo ad accumulare esperienza professionale sul campo, e a espandere la nostra fama ed il nostro buon nome, in tutto il comune di LEGNANO.
          Solo grazie al passaparola, messo in atto dalla nostra clientela, siamo riusciti ad allargare la cerchia dei nostri contatti e siamo riusciti ad affermare il buon nome della nostra impresa di pulizie.
          <strong>Oltre 200 clienti ci ha già scelto!</strong>
          <br/>
          Il nostro <strong>personale qualificato</strong>, i nostri <strong>macchinari di ultima generazione </strong>e la nostra <strong>professionalità </strong>è quello che fa apposta per te!
          I prezzi e le tariffe di manodopera della nostra a ditta di pulizie a Legnano, sono i più convenienti ed allettanti che troverai sul mercato, e possiamo garantirti che a parità di prezzo, non si riuscirai a trovare un’ impresa di pulizie attenta, qualificata e professionale, che possa offrirti un servizio assolutamente impeccabile.
          <strong> Cosa aspetti a richiedere un preventivo gratuito?</strong></p>      

          <div className="section-title text-center mt-25 mb-25">
            <p>Chiama ora e prenota il servizio adatto a te!&nbsp;<strong><a href="tel:+39.02.31055156">+39.02.31055156</a></strong></p>
            <br/>
              <Link to="https://www.manutenpul.it/contact" className="theme-btn">Richiedi Preventivo GRATUITO</Link>
          </div>
        </div>
          
        </div>

      </div>
      <section id="blog" className="blog-section pt-50 pb-50">
       
        <div className="container">
        {/* <div className="row align-items-end"></div> */}
          <div className="row">
            {services.map(service => (
              <Card
              key={service.title}
              image={service.image}
              title={service.title}
              text={service.text} />
            ))}
          </div>
        </div>

        
      </section>

      <Footer></Footer>
    </main>
  )
}

export default LegnanoPage;